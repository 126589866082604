import { apiCall } from "../APICall";
import { eq, option } from "fp-ts";
import { constFalse, constTrue } from "fp-ts/function";
import * as t from "io-ts";
import {
  CompressedFileContent,
  Currency,
  DocumentPurpose,
  GenericError,
  MoneyAmount,
  NonNegative,
  optionFromUndefined,
  PercentageLikeFromNumber,
  PositiveInteger,
} from "../globalDomain";
import {
  CheckExistingClientInput,
  CheckExistingClientOutput,
  eqCheckExistingClientInput,
} from "../IdUpload/ClientData/api";
import {
  AllCitizenships,
  ClientDataOCR,
  PersonalInfoError,
} from "../IdUpload/domain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { nonEmptyArray } from "io-ts-types/nonEmptyArray";
import {
  LocalizedString,
  NonNegativeInteger,
  Positive,
  PositiveIntegerFromString,
} from "design-system";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import {
  AverageIncomingPayments,
  Country,
  Industry,
  PaymentDestination,
  PaymentDestination2,
  SourceOfFunds,
  SourceOfIncome,
  TaxPayerCountry,
  TransactionType,
} from "../KYC/domain";
import { Eq } from "fp-ts/Eq";
import {
  ContractReadInput,
  ContractReadOutput,
  eqContractReadInput,
  eqMarketingConsentReadInput,
  MarketingConsentReadInput,
  PhoneNumberOutput,
  PreContractualDocumentsListOutput,
  TermsAndConditionsOutput,
  TermsAndConditionsReceivedOutput,
} from "../Common/PreContractualDocuments/api";
import {
  eqOtpGenerationInput,
  eqOtpVerifyInput,
  OtpGenerationError,
  OtpGenerationInput,
  OtpVerifyError,
  OtpVerifyInput,
} from "../OTP/domain";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";
import { PersonalDataFromOutput } from "./Confirmation/domain";
import { RuntimeLocaleKey } from "../intl";
import {
  CPIAdditionalQuestions,
  CPIPackageList,
  CreditProtectionInsuranceType,
  SelectedInsurance,
} from "./CustomerOffer/api";
import * as smartKeyApi from "../SmartKey/api";
import { Reader } from "fp-ts/Reader";
import { IO } from "fp-ts/IO";
import {
  IncomeOptionsOutput,
  IncomeOutput,
  IncomeSourceType,
  SpecialIncomeSourceType,
} from "./IncomeForm/domain";
import {
  ConfirmDocumentUploadOutput,
  UploadDocumentOutput,
} from "../MortgageDashboard/Documents/api";
import { withFallback } from "io-ts-types/lib/withFallback";
import { UUID } from "io-ts-types/lib/UUID";
import { AssignedBrokerForBanker, UnderAgeError } from "./domain";
import {
  ApplicationProductType,
  ApplicationStatus,
} from "../Common/ProcessStoreList/api";
import { DocumentMime } from "../Common/documentAPI";
import { CheckPrimaryIdEnums } from "../UKontoSecondPart/api";
import {
  eqLinkGenerationInput,
  LinkGenerationError,
  LinkGenerationInput,
  LinkGenerationOutput,
} from "../PhoneAndEmailVerification/api";
import {
  ChoosePackageTypeInput,
  GetListError,
  PackageListResponse,
} from "../PackagesSelection/api";
import {
  InitVirtualCardResponse,
  PhysicalCardInput,
} from "../VirtualCards/api";
import {
  ClientDataInput,
  ClientDataOutput,
  eqClientDataInput,
} from "../IdUpload/api";
import {
  BonitaStatus,
  SubmitExpensesResponse,
} from "./ExpensesAndAdditionalIncome/ExpensesConfirmation/api";

export const LoanOfferWithLimits = t.type(
  {
    amount: NonNegative,
    apr: PercentageLikeFromNumber,
    currency: Currency,
    bankingFee: NonNegative,
    installment: NonNegative,
    interestRate: PercentageLikeFromNumber,
    minAmount: NonNegative,
    maxAmount: NonNegative,
    minTenor: PositiveInteger,
    maxTenor: PositiveInteger,
    tenor: PositiveInteger,
    totalAmount: NonNegative,
    totalToPay: NonNegative,
    insurance: CreditProtectionInsuranceType,
  },
  "LoanOfferWithLimits"
);

export type LoanOfferWithLimits = t.TypeOf<typeof LoanOfferWithLimits>;

export const LoanOffer = t.type(
  {
    amount: NonNegative,
    currency: Currency,
    tenor: PositiveInteger,
  },
  "LoanOffer"
);

export type LoanOffer = t.TypeOf<typeof LoanOffer>;

export const GenericLoanOfferType = t.keyof(
  {
    COUNTER_OFFER: true,
    EXTENDED_DURATION: true,
    HIGHER_AMOUNT: true,
    CUSTOMER_OFFER: true,
  },
  "GenericLoanOfferType"
);

export type GenericLoanOfferType = t.TypeOf<typeof GenericLoanOfferType>;

export const GenericLoanOffer = t.type(
  {
    bankingFee: optionFromUndefined(NonNegative),
    borrowedAmount: NonNegative,
    cpimonthlyAmount: withFallback(optionFromNullable(Positive), option.none),
    cpiselectedType: RuntimeLocaleKey,
    cpiSelectedName: optionFromNullable(RuntimeLocaleKey),
    currencyCode: Currency,
    installmentAmount: NonNegative,
    interestRateDecimal: PercentageLikeFromNumber,
    tenor: PositiveInteger,
    offerType: optionFromNullable(GenericLoanOfferType),
    rspn: optionFromUndefined(PercentageLikeFromNumber),
    monthlyPayment: optionFromUndefined(NonNegative),
    totalPayableAmount: optionFromUndefined(NonNegative),
    refinancingAmount: optionFromUndefined(NonNegative),
    newMoneyAmount: optionFromUndefined(NonNegative),
  },
  "GenericLoanOffer"
);
export type GenericLoanOffer = t.TypeOf<typeof GenericLoanOffer>;

export const GenericLoanOffers = t.type({
  SALARY_TRANSFER_OFFER: GenericLoanOffer,
  NO_SALARY_TRANSFER_OFFER: GenericLoanOffer,
});
export type GenericLoanOffers = t.TypeOf<typeof GenericLoanOffers>;

export const WayOfIdentification = t.keyof(
  { REMOTE: true, IN_PERSON: true, UNKNOWN: true },
  "WayOfIdentification"
);
export type WayOfIdentification = t.TypeOf<typeof WayOfIdentification>;

const InnerPreapprovedOutput = t.type({
  currency: Currency,
  limittype: t.string,
  interestrate: PercentageLikeFromNumber,
  maximuminstallment: NonNegative,
  maximumpreapprovedlimit: NonNegative,
  maximumpreapprovedtenor: NonNegative,
  minimumproductlimit: NonNegative,
  minimumproducttenor: NonNegative,
  predictedamount: NonNegative,
  predictedexpenses: NonNegative,
  predictedincome: NonNegative,
  predictedtenor: NonNegative,
  upfrontfee: NonNegative,
});

const TLProductType = t.intersection([
  InnerPreapprovedOutput,
  t.type({ producttype: t.literal("TL") }),
]);
const CLProductType = t.intersection([
  InnerPreapprovedOutput,
  t.type({ producttype: t.literal("CL") }),
]);

export type TLProductType = t.TypeOf<typeof TLProductType>;
export type CLProductType = t.TypeOf<typeof CLProductType>;

const TLAndCLLimits = t.partial({
  TL: optionFromUndefined(TLProductType),
  CL: optionFromUndefined(CLProductType),
});

export type TLAndCLLimits = t.TypeOf<typeof TLAndCLLimits>;

const GetPreapprovedLimitsOutput = t.type(
  {
    preapprovedLimits: TLAndCLLimits,
  },
  "getPreapprovedLimitsOutput"
);

export type GetPreapprovedLimitsOutput = t.TypeOf<
  typeof GetPreapprovedLimitsOutput
>;

export const getPreapprovedLimits = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "application",
    "getPreapprovedLimits",
  ],
  inputCodec: t.void,
  outputCodec: GetPreapprovedLimitsOutput,
});

const EntryFee = t.type({
  feeIncluded: optionFromUndefined(t.boolean),
  amount: t.number,
});
export type EntryFee = t.TypeOf<typeof EntryFee>;

export const GenericLoanResponseOutput = t.type(
  {
    amount: NonNegative,
    refinancedAmountExternal: optionFromUndefined(NonNegative),
    monthlyInsuranceAmount: optionFromUndefined(NonNegative),
    insurance: optionFromUndefined(CreditProtectionInsuranceType),
    currency: Currency,
    installment: NonNegative,
    installmentDay: optionFromUndefined(PositiveIntegerFromString),
    installmentDayList: optionFromUndefined(nonEmptyArray(PositiveInteger)),
    interestRate: PercentageLikeFromNumber,
    tenor: PositiveInteger,
    totalPayableAmount: optionFromUndefined(NonNegative),
    apr: PercentageLikeFromNumber,
    maxAllowedAmount: optionFromUndefined(NonNegative),
    minAmount: NonNegative,
    maxAmount: NonNegative,
    minTenor: PositiveInteger,
    maxTenor: PositiveInteger,
    genericLoanOffers: optionFromUndefined(GenericLoanOffers),
    wayOfIdentification: optionFromUndefined(WayOfIdentification),
    salaryTransfer: t.boolean,
    bankingFee: optionFromUndefined(NonNegative),
    totalAmount: NonNegative,
    productType: optionFromUndefined(ApplicationProductType),
    disbursementAccount: optionFromUndefined(NonEmptyString),
    allPrestoSelected: optionFromUndefined(t.boolean),
    refinancedInternalCCOVD: optionFromUndefined(NonNegative),
    displayMinTenorInfoLabel: optionFromUndefined(t.boolean),
    minTenorForPreapproved: optionFromUndefined(NonNegative),
    eligibleForPreapproved: optionFromUndefined(NonNegative),
    cpiEligibleByAge: optionFromUndefined(t.boolean),
    bankingFeeIncluded: optionFromUndefined(t.boolean),
    stepSizeAmount: optionFromUndefined(PositiveInteger),
    stepSizeTenorMonths: optionFromUndefined(PositiveInteger),
  },
  "GenericLoanResponseOutput"
);

export type GenericLoanResponseOutput = t.TypeOf<
  typeof GenericLoanResponseOutput
>;

export const UpdateOfferStatus = t.keyof(
  {
    OK: true,
    EXPOSURE_EXCEEDED: true,
    REFINANCED_CREDITS_LIMIT_EXCEEDED: true,
    GENERIC_PCE_ERROR: true,
  },
  "UpdateOfferStatus"
);

export type UpdateOfferStatus = t.TypeOf<typeof UpdateOfferStatus>;

export const UpdateCustomerOffer = t.type(
  {
    genericLoan: optionFromUndefined(GenericLoanResponseOutput),
    cpiPackageList: optionFromUndefined(CPIPackageList),
    updateOfferStatus: UpdateOfferStatus,
    preapprovedLimits: TLAndCLLimits,
  },
  "UpdateCustomerOffer"
);

export type UpdateCustomerOffer = t.TypeOf<typeof UpdateCustomerOffer>;

export const LoanUpdateInput = t.type(
  {
    amount: optionFromNullable(NonNegative),
    tenor: optionFromNullable(PositiveInteger),
  },
  "LoanUpdateInput"
);

export interface LoanUpdateInput extends t.TypeOf<typeof LoanUpdateInput> {}

export const eqLoanUpdateInput: Eq<LoanUpdateInput> = eq.getStructEq({
  amount: option.getEq(eq.eqNumber),
  tenor: option.getEq(eq.eqNumber),
});

export const updateLoanOffer = apiCall({
  path: ["packages", "loans", "standard-loan", "application", "update"],
  inputCodec: LoanUpdateInput,
  inputEq: eqLoanUpdateInput,
  outputCodec: LoanOfferWithLimits,
  errorCodec: UnderAgeError,
});

export const setSelectedInsurance = apiCall({
  path: ["packages", "loans", "standard-loan", "insurance", "setSelected"],
  inputCodec: SelectedInsurance,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.type({ success: t.boolean }),
});

export const initCustomer = apiCall({
  path: ["clients", "initCustomer"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const initCustomerOffer = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "customerOffer",
    "initCustomerOffer",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UpdateCustomerOffer,
});

export const CustomerOfferUpdateInput = t.type(
  {
    amount: NonNegative,
    tenor: PositiveInteger,
    salaryTransfer: t.boolean,
    installmentDay: PositiveIntegerFromString,
    bankingFeeIncluded: optionFromUndefined(t.boolean),
  },
  "CustomerOfferUpdateInput"
);

export interface CustomerOfferUpdateInput
  extends t.TypeOf<typeof CustomerOfferUpdateInput> {}

export const eqCustomerOfferUpdateInput: Eq<CustomerOfferUpdateInput> = eq.getStructEq(
  {
    amount: eq.eqNumber,
    tenor: eq.eqNumber,
    installmentDay: eq.eqNumber,
    salaryTransfer: eq.eqBoolean,
    bankingFeeIncluded: eq.eqStrict,
  }
);

export const updateCustomerOffer = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "customerOffer",
    "updateCustomerOffer",
  ],
  inputCodec: CustomerOfferUpdateInput,
  inputEq: eqCustomerOfferUpdateInput,
  outputCodec: UpdateCustomerOffer,
});

export const SalaryTransferOfferType = t.keyof({
  NO_SALARY_TRANSFER_OFFER: true,
  SALARY_TRANSFER_OFFER: true,
});
export type SalaryTransferOfferType = t.TypeOf<typeof SalaryTransferOfferType>;

export const SelectedCustomerOfferInput = t.type(
  {
    salaryTransferOfferKey: SalaryTransferOfferType,
  },
  "CustomerOfferUpdateInput"
);

export interface SelectedCustomerOfferInput
  extends t.TypeOf<typeof SelectedCustomerOfferInput> {}

export const eqSelectedCustomerOfferInput: Eq<SelectedCustomerOfferInput> = eq.getStructEq(
  {
    salaryTransferOfferKey: eq.eqString,
  }
);

export const saveSelectedCustomerOffer = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "customerOffer",
    "saveSelectedOffer",
  ],
  inputCodec: SelectedCustomerOfferInput,
  inputEq: eqSelectedCustomerOfferInput,
  outputCodec: t.boolean,
});

export const checkClientExisting = apiCall({
  path: ["packages", "loans", "standard-loan", "client", "checkExisting"],
  inputCodec: CheckExistingClientInput,
  inputEq: eqCheckExistingClientInput,
  outputCodec: CheckExistingClientOutput,
});

export const isExistingClient = apiCall({
  path: ["packages", "loans", "standard-loan", "client", "isExistingClient"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.type({ existingClient: t.boolean }),
});

const EmailUploadLinkData = t.type({
  bankerFlowId: UUID,
  applicationId: NonEmptyString,
});

export const UploadLinkDataStatus = t.keyof({
  OK: true,
  LINK_DATA_NOT_FOUND: true,
  LINK_EXPIRED: true,
  UPLOAD_CONFIRMED: true,
});
export type UploadLinkDataStatus = t.TypeOf<typeof UploadLinkDataStatus>;

const RestoreByClientOutput = t.type({
  emailUploadLinkData: optionFromNullable(EmailUploadLinkData),
  status: UploadLinkDataStatus,
});

type RestoreByClientOutput = t.TypeOf<typeof RestoreByClientOutput>;

export const restoreByClient = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "upload",
    "restoreByClient",
  ],
  inputCodec: t.type({
    linkId: NonEmptyString,
  }),
  inputEq: eq.getStructEq({
    linkId: eq.eqString,
  }),
  outputCodec: RestoreByClientOutput,
});

const checkExistingApplicationOutput = t.type(
  {
    appInitiatedBySame3PUserId: t.boolean,
    applicationExists: t.boolean,
  },
  "checkExistingApplicationOutput"
);

export type checkExistingApplicationOutput = t.TypeOf<
  typeof checkExistingApplicationOutput
>;

export const StartApplicationOutput = t.type(
  {
    amount: NonNegative,
    apr: PercentageLikeFromNumber,
    currency: Currency,
    fee: NonNegative,
    installment: NonNegative,
    interestRate: PercentageLikeFromNumber,
    maxBorrowedAmount: NonNegative,
    minTenor: PositiveInteger,
    maxTenor: PositiveInteger,
    tenor: PositiveInteger,
    totalAmount: NonNegative,
    totalToPay: NonNegative,
    insurance: CreditProtectionInsuranceType,
  },
  "StartApplicationOutput"
);

export type StartApplicationOutput = t.TypeOf<typeof StartApplicationOutput>;

export const initDefaults = apiCall({
  path: ["packages", "loans", "standard-loan", "application", "initDefaults"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: LoanOffer,
  errorCodec: UnderAgeError,
});

export const initDefaults3P = apiCall({
  path: ["packages", "loans", "standard-loan", "application", "initDefaults3P"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const startApplication = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "application",
    "noauth",
    "start",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: LoanOfferWithLimits,
  errorCodec: UnderAgeError,
});

export const checkExistingApplication = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "client",
    "checkExistingApplication",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: checkExistingApplicationOutput,
});

export const openClient = apiCall({
  path: ["packages", "loans", "standard-loan", "client", "openClient"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const PersonalInfoSubmitInput = t.type(
  {
    idType: DocumentPurpose,
  },
  "PersonalInfoSubmitInput"
);

export type PersonalInfoSubmitInput = t.TypeOf<typeof PersonalInfoSubmitInput>;

export const personalInfoSubmit = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "identification",
    "personalInfo",
    "submit",
  ],
  inputCodec: PersonalInfoSubmitInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  errorCodec: PersonalInfoError,
});

export const extractClientData = apiCall({
  path: ["packages", "loans", "standard-loan", "identification", "extractData"],
  inputCodec: ClientDataInput,
  inputEq: eqClientDataInput,
  outputCodec: ClientDataOutput,
});

export const sendMemorandum = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "creditBureau",
    "sendMemorandum",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const CreditChecksApproved = t.literal("Approved");
const CreditChecksRejected = t.literal("CBRejected");

export type CreditChecksResult =
  | t.TypeOf<typeof CreditChecksApproved>
  | t.TypeOf<typeof CreditChecksRejected>;

const PersonalDataNotReadyResult = t.type(
  {
    status: t.literal("RETRY"),
  },
  "PersonalDataNotReadyResult"
);

const PersonalDataApprovedOutput = t.type(
  {
    status: t.literal("OK"),
    personalData: PersonalDataFromOutput,
    result: CreditChecksApproved,
  },
  "PersonalDataApprovedOutput"
);

const PersonalDataRejectedOutput = t.type(
  {
    status: t.literal("OK"),
    result: CreditChecksRejected,
  },
  "PersonalDataRejectedOutput"
);

export const PersonalDataOutput = t.union(
  [
    PersonalDataNotReadyResult,
    PersonalDataApprovedOutput,
    PersonalDataRejectedOutput,
  ],
  "PersonalDataOutput"
);

export type PersonalDataOutput = t.TypeOf<typeof PersonalDataOutput>;

const StartLoanApplicationInput = t.type(
  {
    nrkiConsent: t.boolean,
    srbiConsent: t.boolean,
    telcoConsent: t.boolean,
    solusConsent: t.boolean,
  },
  "StartLoanApplicationInput"
);

export type StartLoanApplicationInput = t.TypeOf<
  typeof StartLoanApplicationInput
>;

const StartLoanApplicationError = t.type(
  {
    translationsKey: optionFromUndefined(RuntimeLocaleKey),
  },
  "StartLoanApplicationError"
);

export type StartLoanApplicationError = t.TypeOf<
  typeof StartLoanApplicationError
>;

const StartLoanApplicationRejectedError = t.type(
  {
    status: t.literal(false),
    errorMessages: nonEmptyArray(NonEmptyString),
  },
  "StartLoanApplicationRejectedError"
);

const StartLoanApplicationSuccess = t.type(
  {
    status: t.literal(true),
    applicationNumber: NonEmptyString,
  },
  "StartLoanApplicationSuccess"
);

const StartLoanApplicationOutput = t.union([
  StartLoanApplicationRejectedError,
  StartLoanApplicationSuccess,
]);

export type StartLoanApplicationOutput = t.TypeOf<
  typeof StartLoanApplicationOutput
>;

// @TODO: probably this call (and data structure) will be removed. Ongoing discussion with BFF if used in restore.
export const getPersonalData = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: PersonalDataOutput,
  path: [
    "packages",
    "loans",
    "standard-loan",
    "application",
    "getPersonalData",
  ],
});

const CreditBureauPendingStatus = t.type(
  {
    cbResultReady: t.literal(false),
  },
  "CreditBureauPendingStatus"
);

const CreditBureauRejectionStatus = t.type(
  {
    cbResultReady: t.literal(true),
    cbDecision: t.literal("Rejected"),
  },
  "CreditBureauRejectionStatus"
);

const CreditBureauApprovalStatus = t.type(
  {
    cbResultReady: t.literal(true),
    cbDecision: t.literal("Approved"),
    hasRefinancingCredits: t.boolean,
  },
  "CreditBureauApprovalStatus"
);
type CreditBureauApprovalStatus = t.TypeOf<typeof CreditBureauApprovalStatus>;

export const CreditBureauStatus = t.union(
  [
    CreditBureauPendingStatus,
    CreditBureauRejectionStatus,
    CreditBureauApprovalStatus,
  ],
  "CreditBureauStatus"
);
export type CreditBureauStatus = t.TypeOf<typeof CreditBureauStatus>;

export function foldCreditBureauStatus<T>(matches: {
  whenPending: IO<T>;
  whenRejected: IO<T>;
  whenApproved: Reader<CreditBureauApprovalStatus, T>;
}): Reader<CreditBureauStatus, T> {
  return status => {
    if (!status.cbResultReady) {
      return matches.whenPending();
    } else if (status.cbDecision === "Rejected") {
      return matches.whenRejected();
    } else {
      return matches.whenApproved(status);
    }
  };
}

export const checkCreditBureauStatus = apiCall({
  path: ["packages", "loans", "standard-loan", "creditBureau", "status"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CreditBureauStatus,
});

export const startLoanApplication = apiCall({
  inputCodec: StartLoanApplicationInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: StartLoanApplicationOutput,
  errorCodec: StartLoanApplicationError,
  path: [
    "packages",
    "loans",
    "standard-loan",
    "application",
    "startLoanApplication",
  ],
});

export const IncomeSource = t.keyof({
  Employment: true,
  IncomeFromAbroad: true,
  SelfEmployed: true,
  Pensioner: true,
  MaternityLeave: true,
  GovernamentalPension: true,
  MilitaryAid: true,
  Level3HandicapPension: true,
  RentalIncome: true,
  Alimony: true,
});

export type IncomeSource = t.TypeOf<typeof IncomeSource>;

const AdditionalIncomeRecord = t.type(
  {
    additionalIncome: IncomeOutput,
  },
  "AdditionalIncomeRecord"
);

export type AdditionalIncomeRecord = t.TypeOf<typeof AdditionalIncomeRecord>;

const AdditionalIncomeRecordId = t.type(
  {
    recordId: NonEmptyString,
  },
  "AdditionalIncomeRecordId"
);

export type AdditionalIncomeRecordId = t.TypeOf<
  typeof AdditionalIncomeRecordId
>;

const AdditionalIncomeStatusOutput = t.type(
  {
    statusCode: t.literal("OK"), // @todo: Check with BFF for possible values / removing statusCodeValue.
  },
  "AdditionalIncomeStatusOutput"
);

export type AdditionalIncomeStatusOutput = t.TypeOf<
  typeof AdditionalIncomeStatusOutput
>;

export const additionalIncomeAdd = apiCall({
  path: ["packages", "loans", "standard-loan", "additionalIncome", "add"],
  inputCodec: AdditionalIncomeRecord,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: AdditionalIncomeRecord,
});

export const additionalIncomeEdit = apiCall({
  path: ["packages", "loans", "standard-loan", "additionalIncome", "edit"],
  inputCodec: AdditionalIncomeRecord,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: AdditionalIncomeRecord,
});

export const additionalIncomeRemove = apiCall({
  path: ["packages", "loans", "standard-loan", "additionalIncome", "remove"],
  inputCodec: AdditionalIncomeRecordId,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: AdditionalIncomeStatusOutput,
});

export const AdditionalIncomeLFDecision = t.keyof({
  Approved: true,
  Rejected: true,
  Counteroffer: true,
  Pending: true,
});

export type AdditionalIncomeLFDecision = t.TypeOf<
  typeof AdditionalIncomeLFDecision
>;

export const AdditionalIncomeProceedNextOutput = t.type(
  {
    lfDecision: AdditionalIncomeLFDecision,
  },
  "AdditionalIncomeProceedNextOutput"
);

export type AdditionalIncomeProceedNextOutput = t.TypeOf<
  typeof AdditionalIncomeProceedNextOutput
>;

export const additionalIncomeNext = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "additionalIncome",
    "proceedNext",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: AdditionalIncomeProceedNextOutput,
});

export const sendAdditionalIncome = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "additionalIncome",
    "sendAdditionalIncome",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: AdditionalIncomeProceedNextOutput,
});

const AdditionalIncomeListOutput = t.type(
  {
    additionalIncomeList: t.array(IncomeOutput), // it can be []
    incomeOptions: optionFromUndefined(IncomeOptionsOutput),
    mainSourceOfIncome: IncomeSourceType,
    mainSpecialTypeOfIncome: optionFromUndefined(SpecialIncomeSourceType),
  },
  "AdditionalIncomeListOutput"
);

export type AdditionalIncomeListOutput = t.TypeOf<
  typeof AdditionalIncomeListOutput
>;

export const additionalIncomeList = apiCall({
  path: ["packages", "loans", "standard-loan", "additionalIncome", "list"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: AdditionalIncomeListOutput,
});

export const BonitaResponseOutput = t.type(
  {
    submitExpensesResponse: optionFromUndefined(SubmitExpensesResponse),
    proceedWithAdditionalIncomeResponse: optionFromUndefined(
      AdditionalIncomeProceedNextOutput
    ),
    bonitaStatus: BonitaStatus,
  },
  "BonitaResponse"
);
export const getBonitaStatus = apiCall({
  path: ["packages", "loans", "standard-loan", "bonita", "status"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: BonitaResponseOutput,
});

export const PersonalAndTaxInfo = t.type({
  politicallyExposedPerson: t.boolean,
  taxPayerOutsideCzechRepublic: t.boolean,
  otherTaxPayerCountries: t.array(TaxPayerCountry),
  usCitizenOrTaxPayer: t.boolean,
  ssn: optionFromUndefined(t.string),
  specialRelationships: t.boolean,
  riskyActivities: optionFromUndefined(t.boolean),
  industry: optionFromUndefined(Industry),
  secondCitizenship: optionFromUndefined(AllCitizenships),
});

export type PersonalAndTaxInfo = t.TypeOf<typeof PersonalAndTaxInfo>;

export const TransactionsInfo1 = t.type({
  paymentDestination: PaymentDestination,
  paymentDestinationLabel: LocalizedString,
  paymentDestinationCountries: optionFromUndefined(t.string),
  transactionType: TransactionType,
  transactionTypeLabel: LocalizedString,
  cashTransactionMotivation: optionFromUndefined(t.string),
});

export type TransactionsInfo1 = t.TypeOf<typeof TransactionsInfo1>;

//used for new kyc questionnaire, under FF
export const TransactionsInfo2 = t.type({
  averageMonthlyIncomePayments: optionFromUndefined(AverageIncomingPayments),
  paymentDestination: optionFromUndefined(PaymentDestination2),
  paymentDestinationLabel: optionFromUndefined(LocalizedString),
  paymentDestinationCountries: optionFromUndefined(t.string),
  paymentDestinationCountriesWithLabels: optionFromUndefined(t.array(Country)),
  reasonOfTransactions: optionFromUndefined(t.string),
  sourceOfFunds: optionFromUndefined(SourceOfFunds),
  sourceOfIncome: optionFromUndefined(SourceOfIncome),
  employerName: optionFromUndefined(t.string),
  companyICO: optionFromUndefined(t.string),
});
export type TransactionsInfo2 = t.TypeOf<typeof TransactionsInfo2>;

export const TransactionsInfo = t.union([TransactionsInfo1, TransactionsInfo2]);
export type TransactionsInfo = t.TypeOf<typeof TransactionsInfo>;

export const StandardLoanKYCAnswers = t.type({
  transactionsInfo: TransactionsInfo,
  personalAndTaxInfo: PersonalAndTaxInfo,
});
export type StandardLoanKYCAnswers = t.TypeOf<typeof StandardLoanKYCAnswers>;

const SubmitKYCInput = t.type({
  answers: StandardLoanKYCAnswers,
});
type SubmitKYCAnswersInput = t.TypeOf<typeof SubmitKYCInput>;

const eqSubmitKYCInput: Eq<SubmitKYCAnswersInput> = eq.getStructEq({
  answers: eq.eqStrict,
});

const SubmitKYCOutput = t.type(
  {
    needsMicropayment: t.boolean,
  },
  "SubmitKYCOutput"
);
export type SubmitKYCOutput = t.TypeOf<typeof SubmitKYCOutput>;

export const submitKYC = apiCall({
  inputEq: eqSubmitKYCInput,
  path: ["packages", "loans", "standard-loan", "knowYourCustomer", "submit"],
  inputCodec: SubmitKYCInput,
  outputCodec: SubmitKYCOutput,
});

export const deleteApplication = apiCall({
  path: ["packages", "loans", "standard-loan", "applicationState", "delete"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

export const saveApplication = apiCall({
  path: ["packages", "loans", "standard-loan", "applicationState", "save"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

export const closeApplication = apiCall({
  path: ["packages", "loans", "standard-loan", "applicationState", "close"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

export const GetApplicationIdOutput = t.type(
  {
    applicationId: optionFromNullable(NonEmptyString),
  },
  "GetApplicationIdOutput"
);

export type GetApplicationIdOutput = t.TypeOf<typeof GetApplicationIdOutput>;

export const getApplicationId = apiCall({
  path: ["utilities", "application", "getApplicationId"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: GetApplicationIdOutput,
});

const ExitProcessReason = t.keyof(
  {
    HIGHER_AMOUNT: true,
    REPAYMENTPERIOD: true,
    BOTH: true,
    NONE: true,
  },
  "ExitProcessReason"
);
type ExitProcessReason = t.TypeOf<typeof ExitProcessReason>;

const ExitProcessInput = t.union([
  t.type(
    {
      reason: ExitProcessReason,
    },
    "ExitProcessInput"
  ),
  t.type(
    {
      reason: t.literal("OTHER"),
      reasonDetails: t.string,
    },
    "ExitProcessInputOther"
  ),
]);
export type ExitProcessInput = t.TypeOf<typeof ExitProcessInput>;

const eqExitProcessInput: Eq<ExitProcessInput> = eq.getStructEq({
  reason: eq.eqStrict,
});

export const exitProcess = apiCall({
  inputCodec: ExitProcessInput,
  inputEq: eqExitProcessInput,
  path: ["packages", "loans", "feedback", "exitprocess"],
});

export const LoanType = t.keyof(
  {
    LOAN: true,
    CREDIT_CARD: true,
    OVERDRAFT: true,
  },
  "LoanType"
);

export type LoanType = t.TypeOf<typeof LoanType>;

const LoanItem = t.type(
  {
    type: LoanType,
    originalAmount: MoneyAmount,
    remainingAmount: MoneyAmount,
    recordId: NonEmptyString,
  },
  "LoanItem"
);

const LoanItemRework = t.type(
  {
    type: optionFromUndefined(LoanType),
    originalAmount: optionFromUndefined(MoneyAmount),
    remainingAmount: optionFromUndefined(MoneyAmount),
    recordId: optionFromUndefined(NonEmptyString),
  },
  "LoanItemRework"
);

export type LoanItem = t.TypeOf<typeof LoanItem>;

const InternalLoanItem = t.intersection(
  [
    t.type({
      selectedForRefinance: t.boolean,
      startDate: optionFromUndefined(DateFromISOString),
    }),
    LoanItem,
  ],
  "InternalLoanItem"
);

const InternalLoanItemRework = t.intersection(
  [
    t.type({
      selectedForRefinance: optionFromUndefined(t.boolean),
      startDate: optionFromUndefined(DateFromISOString),
    }),
    LoanItemRework,
  ],
  "InternalLoanItemRework"
);

export type InternalLoanItem = t.TypeOf<typeof InternalLoanItem>;
export type InternalLoanItemRework = t.TypeOf<typeof InternalLoanItemRework>;

const ExternalLoanItem = t.intersection(
  [
    t.type({
      creditCardNumber: optionFromUndefined(t.string),
      creditor: optionFromUndefined(t.string),
      creditorCode: optionFromUndefined(NonEmptyString),
      variableSymbol: optionFromUndefined(NonEmptyString),
      accountForDisbursment: optionFromUndefined(NonEmptyString),
    }),
    LoanItem,
  ],
  "ExternalLoanItem"
);

const ExternalLoanItemRework = t.intersection(
  [
    t.type({
      creditCardNumber: optionFromUndefined(t.string),
      creditor: optionFromUndefined(t.string),
      variableSymbol: optionFromUndefined(NonEmptyString),
      accountForDisbursment: optionFromUndefined(NonEmptyString),
    }),
    LoanItemRework,
  ],
  "ExternalLoanItemRework"
);

export type ExternalLoanItem = t.TypeOf<typeof ExternalLoanItem>;
export type ExternalLoanItemRework = t.TypeOf<typeof ExternalLoanItemRework>;

export const InternalLoansList = t.type(
  { liabilitiesList: t.array(InternalLoanItem) },
  "InternalLoansList"
);

export const InternalLoansListRework = t.type(
  { liabilitiesList: t.array(InternalLoanItemRework) },
  "InternalLoansListRework"
);

export type InternalLoansList = t.TypeOf<typeof InternalLoansList>;
export type InternalLoansListRework = t.TypeOf<typeof InternalLoansListRework>;

export const ExternalLoansList = t.type(
  { liabilitiesList: t.array(ExternalLoanItem) },
  "ExternalLoansList"
);

export const ExternalLoansListRework = t.type(
  { liabilitiesList: t.array(ExternalLoanItemRework) },
  "ExternalLoansListRework"
);

export type ExternalLoansList = t.TypeOf<typeof ExternalLoansList>;
export type ExternalLoansListRework = t.TypeOf<typeof ExternalLoansListRework>;

const AccountsForRefinancingOutput = t.type(
  {
    accountNumber: optionFromUndefined(NonEmptyString),
    accountCurrency: Currency,
    internalLoans: optionFromUndefined(InternalLoansList),
    externalLoans: optionFromUndefined(ExternalLoansList),
  },
  "AccountsForRefinancingOutput"
);

export type AccountsForRefinancingOutput = t.TypeOf<
  typeof AccountsForRefinancingOutput
>;

export const getAccountsForRefinancing = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "creditBureau",
    "getAccountsForRefinancing",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: AccountsForRefinancingOutput,
});

const RefinancingListLoanItem = t.intersection(
  [
    t.type({
      selectedForRefinance: t.boolean,
      startDate: optionFromUndefined(DateFromISOString),
    }),
    LoanItem,
  ],
  "RefinancingListLoanItem"
);

export type RefinancingListLoanItem = t.TypeOf<typeof RefinancingListLoanItem>;

const RefinancingLoanList = t.type(
  { liabilitiesList: t.array(RefinancingListLoanItem) },
  "RefinancingLoanList"
);

const RefinancingLoanListOutput = t.type(
  {
    internalLoans: optionFromUndefined(RefinancingLoanList),
    externalLoans: optionFromUndefined(RefinancingLoanList),
    refinancedAmountInternal: t.number,
    refinancedAmountExternal: t.number,
  },
  "RefinancingLoanListOutput"
);

export type RefinancingLoanListOutput = t.TypeOf<
  typeof RefinancingLoanListOutput
>;

export const refinancingLoanList = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "creditBureau",
    "refinancingLoanList",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: RefinancingLoanListOutput,
});

const FollowUpPreliminaryStatusOutput = t.type(
  {
    statusCode: t.keyof({
      OK: true,
      NOK: true,
    }),
  },
  "FollowUpPreliminaryStatusOutput"
);

export type FollowUpPreliminaryStatusOutput = t.TypeOf<
  typeof FollowUpPreliminaryStatusOutput
>;

export const followUpPreliminaryStatus = apiCall({
  path: ["packages", "loans", "standard-loan", "client", "preliminaryStatus"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: FollowUpPreliminaryStatusOutput,
});

const Credentials = t.type(
  {
    password: NonEmptyString,
    pin: optionFromNullable(NonEmptyString),
    userId: optionFromNullable(NonEmptyString),
  },
  "Credentials"
);
type Credentials = t.TypeOf<typeof Credentials>;

const eqCredentials: Eq<Credentials> = eq.getStructEq({
  password: eq.eqString,
  pin: option.getEq(eq.eqString),
  userId: option.getEq(eq.eqString),
});

const SubmitCredentialsInput = t.type(
  {
    profile: Credentials,
  },
  "SubmitCredentialsInput"
);
type SubmitCredentialsInput = t.TypeOf<typeof SubmitCredentialsInput>;

const eqSubmitCredentialsInput: Eq<SubmitCredentialsInput> = eq.getStructEq({
  profile: eqCredentials,
});

export const submitCredentials = apiCall({
  inputCodec: SubmitCredentialsInput,
  inputEq: eqSubmitCredentialsInput,
  path: ["packages", "loans", "standard-loan", "client", "submitCredentials"],
  outputCodec: t.unknown,
});

const ClientStatus = t.keyof({
  PENDING: true,
  DONE: true,
  ERROR: true,
});

export const ClientStatusErrorId = t.keyof({
  RiskyClient: true,
  KycAssessmentNotAvailable: true,
  KycAssessmentResultNotAvailable: true,
});
export type ClientStatusErrorId = t.TypeOf<typeof ClientStatusErrorId>;

const ClientStatusOutput = t.type(
  {
    status: ClientStatus,
    id: optionFromUndefined(ClientStatusErrorId),
    message: optionFromUndefined(
      t.keyof({
        RiskyClient: true,
        KycAssessmentNotAvailable: true,
        KycAssessmentResultNotAvailable: true,
      })
    ),
  },
  "ClientStatusOutput"
);

export type ClientStatusOutput = t.TypeOf<typeof ClientStatusOutput>;

export const getClientStatus = apiCall({
  path: ["packages", "loans", "standard-loan", "client", "status"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ClientStatusOutput,
});

const SignatureStatus = t.keyof({
  NONE: true,
  PENDING: true,
  DONE: true,
  ERROR: true,
});

const SignatureStatusOutput = t.type(
  {
    signatureStatus: SignatureStatus,
  },
  "SignatureStatusOutput"
);

export type SignatureStatus = t.TypeOf<typeof SignatureStatus>;
export type SignatureStatusOutput = t.TypeOf<typeof SignatureStatusOutput>;

export const getSignatureStatus = apiCall({
  path: ["packages", "loans", "standard-loan", "signatureStatus"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: SignatureStatusOutput,
});

const RemoteSignatureStatus = t.keyof({
  PENDING: true,
  SIGNING: true,
  DONE: true,
  ERROR: true,
});

export const getKnowYourCustomerStatus = apiCall({
  path: ["packages", "loans", "standard-loan", "knowYourCustomer", "status"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ClientStatusOutput,
});

export const contractRead = apiCall({
  inputCodec: ContractReadInput,
  inputEq: eqContractReadInput,
  outputCodec: ContractReadOutput,
  path: ["packages", "loans", "standard-loan", "markDocumentRead"],
});

export const termsAndConditionsReceived = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: TermsAndConditionsReceivedOutput,
  path: ["packages", "loans", "standard-loan", "termsAndConditionsReceived"],
});

export const marketingConsent = apiCall({
  inputCodec: MarketingConsentReadInput,
  inputEq: eqMarketingConsentReadInput,
  path: ["packages", "loans", "standard-loan", "marketingConsent"],
});

export const preContractualDocuments = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: PreContractualDocumentsListOutput,
  path: ["packages", "loans", "standard-loan", "contractualDocumentList"],
});

export const termsAndConditions = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: TermsAndConditionsOutput,
  path: [
    "packages",
    "loans",
    "standard-loan",
    "termsAndConditionsDocumentList",
  ],
});

export const phoneNumber = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: PhoneNumberOutput,
  path: ["clients", "identification", "phoneNumber"],
});

export const LFStatus = t.keyof(
  {
    Approved: true,
    Pending: true,
    Rejected: true,
  },
  "LFStatus"
);
export type LFStatus = t.TypeOf<typeof LFStatus>;

const AuthorizeWithOTPOutput = t.type(
  {
    authorize: t.boolean,
    remainingRequests: NonNegativeInteger,
    transactionId: LocalizedString,
    lfStatus: optionFromUndefined(LFStatus),
  },
  "AuthorizeWithOTPOutput"
);

export const authorizeWithOTP = apiCall({
  path: ["packages", "loans", "standard-loan", "authorizeWithOTP"],
  inputCodec: OtpGenerationInput,
  inputEq: eqOtpGenerationInput,
  errorCodec: t.union([OtpGenerationError, GenericError]),
  outputCodec: AuthorizeWithOTPOutput,
});

const RemoteSignatureStatusOutput = t.type(
  {
    status: RemoteSignatureStatus,
    lfStatus: optionFromUndefined(LFStatus),
  },
  "SignatureStatusOutput"
);

export type RemoteSignatureStatusOutput = t.TypeOf<
  typeof RemoteSignatureStatusOutput
>;

export const getRemoteSignatureStatus = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "remoteSignatureStatus",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: RemoteSignatureStatusOutput,
});

export const GenerateOTPOutput = t.type(
  {
    remainingRequests: NonNegativeInteger,
  },
  "GenerateOTPOutput"
);

export const generateOtp = apiCall({
  path: ["packages", "loans", "standard-loan", "application", "otp"],
  inputCodec: OtpGenerationInput,
  inputEq: eqOtpGenerationInput,
  outputCodec: GenerateOTPOutput,
  errorCodec: t.union([OtpGenerationError, GenericError]),
});

export const otpVerify = apiCall({
  inputEq: eqOtpVerifyInput,
  path: ["packages", "loans", "standard-loan", "application", "otp", "verify"],
  inputCodec: OtpVerifyInput,
  errorCodec: OtpVerifyError,
});

export const checkAuthorizationOTP = apiCall({
  inputEq: eqOtpVerifyInput,
  path: ["packages", "loans", "standard-loan", "checkAuthorizationOTP"],
  inputCodec: OtpVerifyInput,
  errorCodec: OtpVerifyError,
});

const MicroTransactionDataOutput = t.type(
  {
    accountNumber: NonEmptyString,
    bankCode: NonEmptyString,
    bankName: NonEmptyString,
    prefixCode: t.string,
  },
  "MicroTransactionDataOutput"
);

export type MicroTransactionDataOutput = t.TypeOf<
  typeof MicroTransactionDataOutput
>;

const IncomeAndPersonalData = t.type({
  income: IncomeOutput,
  personalData: optionFromUndefined(
    t.type({
      personalData: PersonalDataFromOutput,
    })
  ),
});

export type IncomeAndPersonalData = t.TypeOf<typeof IncomeAndPersonalData>;

const IncomeAndPersonalDataDTO = t.type({
  income: IncomeOutput,
  personalData: PersonalDataFromOutput,
});

export type IncomeAndPersonalDataDTO = t.TypeOf<
  typeof IncomeAndPersonalDataDTO
>;

const OfferTypeDecision = t.keyof(
  {
    Approved: true,
    Counteroffer: true,
    Rejected: true,
  },
  "OfferTypeDecision"
);

export type OfferTypeDecision = t.TypeOf<typeof OfferTypeDecision>;

const OfferTypeOutput = t.type(
  {
    lfDecision: OfferTypeDecision,
  },
  "OfferTypeOutput"
);

export type OfferTypeOutput = t.TypeOf<typeof OfferTypeOutput>;

export const CoreCalendar = t.type(
  {
    days: NonEmptyString,
    month: PositiveInteger,
    year: PositiveInteger,
  },
  "CoreCalendar"
);

export type CoreCalendar = t.TypeOf<typeof CoreCalendar>;

export const getCoreCalendar = apiCall({
  path: ["utilities", "cached", "noauth", "coreCalendar"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: nonEmptyArray(CoreCalendar),
});

// =========================================================================================
//  STEPS types blocks for RestoreNewestLoanApplicationOutput
//  discriminant uninon type on festep to check in simple way required and nullable fields
// =========================================================================================

const RestoreNewestLoanApplicationCreditChecksStepOutput = t.type(
  {
    festep: t.literal("CREDIT_CHECKS"),
    additionalQuestionsCPIRequest: optionFromNullable(CPIAdditionalQuestions),
    allPreApprovedLimitsResponse: optionFromUndefined(TLAndCLLimits),
    genericLoanResponse: GenericLoanResponseOutput,
    existingClient: t.boolean,
  },
  "RestoreNewestLoanApplicationCreditChecksStepOutput"
);

export type RestoreNewestLoanApplicationCreditChecksStepOutput = t.TypeOf<
  typeof RestoreNewestLoanApplicationCreditChecksStepOutput
>;

const RestoreNewestLoanApplicationReviewInformationStepOutput = t.type(
  {
    festep: t.literal("REVIEW_INFORMATION"),
    additionalQuestionsCPIRequest: optionFromNullable(CPIAdditionalQuestions),
    allPreApprovedLimitsResponse: optionFromUndefined(TLAndCLLimits),
    startStandardLoanApplicationRequest: StartLoanApplicationInput,
    genericLoanResponse: GenericLoanResponseOutput,
    existingClient: t.boolean,
  },
  "RestoreNewestLoanApplicationReviewInformationStepOutput"
);

export type RestoreNewestLoanApplicationReviewInformationStepOutput = t.TypeOf<
  typeof RestoreNewestLoanApplicationReviewInformationStepOutput
>;

const FinalizeOfferToAdditionalIncomeSteps = t.keyof(
  {
    FINALISE_OFFER_MAIN_SCREEN: true,
    EXPENSE: true,
    ADDITIONAL_INCOME: true,
  },
  "ExpenseToAdditionIncomeSteps"
);

const RestoreNewestLoanApplicationExpenseToAdditionalIncomeStepsOutput = t.type(
  {
    festep: FinalizeOfferToAdditionalIncomeSteps,
    additionalQuestionsCPIRequest: optionFromNullable(CPIAdditionalQuestions),
    allPreApprovedLimitsResponse: optionFromUndefined(TLAndCLLimits),
    startStandardLoanApplicationRequest: StartLoanApplicationInput,
    incomeAndPersonalData: IncomeAndPersonalData,
    genericLoanResponse: GenericLoanResponseOutput,
    creditBureauStatusResponse: CreditBureauStatus,
    existingClient: t.boolean,
  },
  "RestoreNewestLoanApplicationExpenseToAdditionalIncomeStepsOutput"
);

export type RestoreNewestLoanApplicationExpenseToAdditionalIncomeStepsOutput = t.TypeOf<
  typeof RestoreNewestLoanApplicationExpenseToAdditionalIncomeStepsOutput
>;

const ChooseOfferToKycSteps = t.keyof(
  {
    CHOOSE_OFFER: true,
    REVIEW_OFFER: true,
    KYC: true,
  },
  "ChooseOfferToKycSteps"
);

const RestoreNewestLoanApplicationChooseOfferToKycStepsOutput = t.type(
  {
    festep: ChooseOfferToKycSteps,
    additionalQuestionsCPIRequest: optionFromNullable(CPIAdditionalQuestions),
    allPreApprovedLimitsResponse: optionFromUndefined(TLAndCLLimits),
    startStandardLoanApplicationRequest: StartLoanApplicationInput,
    incomeAndPersonalData: IncomeAndPersonalData,
    genericLoanResponse: GenericLoanResponseOutput,
    creditBureauStatusResponse: CreditBureauStatus,
    proceedNextResponse: optionFromUndefined(OfferTypeOutput),
    existingClient: t.boolean,
  },
  "RestoreNewestLoanApplicationChooseOfferToKycStepsOutput"
);

export type RestoreNewestLoanApplicationChooseOfferToKycStepsOutput = t.TypeOf<
  typeof RestoreNewestLoanApplicationChooseOfferToKycStepsOutput
>;

const RestoreNewestLoanApplicationMicroPaymentStepOutput = t.type(
  {
    festep: t.literal("MICROPAYMENT"),
    additionalQuestionsCPIRequest: optionFromNullable(CPIAdditionalQuestions),
    allPreApprovedLimitsResponse: optionFromUndefined(TLAndCLLimits),
    startStandardLoanApplicationRequest: StartLoanApplicationInput,
    incomeAndPersonalData: IncomeAndPersonalData,
    genericLoanResponse: GenericLoanResponseOutput,
    personalDataResponse: PersonalDataOutput,
    creditBureauStatusResponse: CreditBureauStatus,
    proceedNextResponse: optionFromUndefined(OfferTypeOutput),
    existingClient: t.boolean,
  },
  "RestoreNewestLoanApplicationMicroPaymentStepOutput"
);

export type RestoreNewestLoanApplicationMicroPaymentStepsOutput = t.TypeOf<
  typeof RestoreNewestLoanApplicationMicroPaymentStepOutput
>;

const StandardLoanKycOutput = t.type(
  {
    transactionsInfo: TransactionsInfo,
    personalAndTaxInfo: optionFromUndefined(PersonalAndTaxInfo),
  },
  "StandardLoanKycOutput"
);

const StandardLoanKycRequest = t.type(
  {
    answers: StandardLoanKycOutput,
  },
  "StandardLoanKycRequest"
);

const RestoreNewestLoanApplicationPersonalDataStepOutput = t.type(
  {
    festep: t.literal("PERSONAL_DATA"),
    additionalQuestionsCPIRequest: optionFromNullable(CPIAdditionalQuestions),
    allPreApprovedLimitsResponse: optionFromNullable(TLAndCLLimits),
    startStandardLoanApplicationRequest: StartLoanApplicationInput,
    incomeAndPersonalData: IncomeAndPersonalData,
    standardLoanKycRequest: optionFromUndefined(StandardLoanKycRequest),
    genericLoanResponse: GenericLoanResponseOutput,
    personalDataResponse: PersonalDataOutput,
    creditBureauStatusResponse: CreditBureauStatus,
    proceedNextResponse: optionFromUndefined(OfferTypeOutput),
    existingClient: t.boolean,
  },
  "RestoreNewestLoanApplicationPersonalDataStepOutput"
);

export type RestoreNewestLoanApplicationPersonalDataStepOutput = t.TypeOf<
  typeof RestoreNewestLoanApplicationPersonalDataStepOutput
>;

const RestoreNewestLoanApplicationOtherSteps = t.keyof(
  {
    PACKAGE_SELECTION: true,
    VIRTUAL_CARDS: true,
    ACCOUNTS_FOR_REFINANCING: true,
    CLIENT_CREDENTIALS: true,
    UPLOAD_DOCUMENTS: true,
    SIGNATURE: true,
    FINAL_PAGE: true,
    UNDER_REWORK: true,
    AUTHORIZE_PUSH: true,
    REMOTE_SIGNATURE: true,
  },
  "RestoreNewestLoanApplicationOtherSteps"
);

const RestoreNewestLoanApplicationOtherStepsOutput = t.type(
  {
    festep: RestoreNewestLoanApplicationOtherSteps,
    additionalQuestionsCPIRequest: optionFromNullable(CPIAdditionalQuestions),
    allPreApprovedLimitsResponse: optionFromNullable(TLAndCLLimits),
    genericLoanResponse: GenericLoanResponseOutput,
    personalDataResponse: PersonalDataOutput,
    incomeAndPersonalData: IncomeAndPersonalData,
    microTransactionData: optionFromUndefined(MicroTransactionDataOutput),
    standardLoanKycRequest: optionFromUndefined(StandardLoanKycRequest),
    startStandardLoanApplicationRequest: StartLoanApplicationInput,
    creditBureauStatusResponse: CreditBureauStatus,
    proceedNextResponse: optionFromUndefined(OfferTypeOutput),
    personalDataConfirmation: optionFromUndefined(
      t.type({
        primary: optionFromUndefined(ClientDataOCR),
        secondary: optionFromUndefined(ClientDataOCR),
      })
    ),
    existingClient: t.boolean,
  },
  "RestoreNewestLoanApplicationOtherStepsOutput"
);

export type RestoreNewestLoanApplicationOtherStepsOutput = t.TypeOf<
  typeof RestoreNewestLoanApplicationOtherStepsOutput
>;

const RestoreNewestLoanApplicationOutput = t.intersection(
  [
    t.union([
      RestoreNewestLoanApplicationCreditChecksStepOutput,
      RestoreNewestLoanApplicationReviewInformationStepOutput,
      RestoreNewestLoanApplicationExpenseToAdditionalIncomeStepsOutput,
      RestoreNewestLoanApplicationChooseOfferToKycStepsOutput,
      RestoreNewestLoanApplicationMicroPaymentStepOutput,
      RestoreNewestLoanApplicationPersonalDataStepOutput,
      RestoreNewestLoanApplicationOtherStepsOutput,
    ]),
    t.partial({
      cardProviderChange: t.boolean,
    }),
  ],
  "RestoreNewestLoanApplicationOutput"
);

export type RestoreNewestLoanApplicationOutput = t.TypeOf<
  typeof RestoreNewestLoanApplicationOutput
>;

const ParallelFlowParameters = t.type({
  hasCAFlowInProgress: t.boolean,
  hasCAFlowSentToBO: t.boolean,
  hasCFFlowSentToBO: t.boolean,
  date: optionFromUndefined(t.number),
  existingApplicationId: optionFromUndefined(NonEmptyString),
});
export type ParallelFlowParameters = t.TypeOf<typeof ParallelFlowParameters>;

export const hasParallelFlow = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "genericloans",
    "restore",
    "restrictParallelFlow",
  ],
  inputCodec: t.void,
  outputCodec: optionFromNullable(ParallelFlowParameters),
});

const NewestApplicationInProgressOutput = t.type(
  {
    applicationId: optionFromNullable(NonEmptyString),
    responseWithDifferentBroker: t.boolean,
    parallelFlowParameters: ParallelFlowParameters,
    restoreData: optionFromNullable(RestoreNewestLoanApplicationOutput),
    status: optionFromNullable(ApplicationStatus),
    cardProviderChange: t.boolean,
  },
  "NewestApplicationInProgressOutput"
);

export type NewestApplicationInProgressOutput = t.TypeOf<
  typeof NewestApplicationInProgressOutput
>;

const CheckClientAge = t.type({
  underAge: t.boolean,
});
export type CheckClientAge = t.TypeOf<typeof CheckClientAge>;

export const checkMinimumAge = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "client", "checkClientAge"],
  inputCodec: t.void,
  outputCodec: CheckClientAge,
  errorCodec: GenericError,
});

export const checkForeign = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "client", "checkForeign"],
  inputCodec: t.void,
  outputCodec: t.boolean,
  errorCodec: GenericError,
});

const ExistingClientCheckNotesCF = t.type({
  hasBlockingNotes: t.boolean,
});
export type ExistingClientCheckNotesCF = t.TypeOf<
  typeof ExistingClientCheckNotesCF
>;

export const checkNotes = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "client", "checkNotes"],
  inputCodec: t.void,
  outputCodec: ExistingClientCheckNotesCF,
  errorCodec: GenericError,
});

export const CheckPrimaryIdEnumsCF = t.union([
  CheckPrimaryIdEnums,
  t.keyof({
    UPDATE_PASSWORD: true,
    UPDATE_ID_CONTACTS_PASSWORD: true,
    UPDATE_ID_PASSWORD: true,
    UPDATE_ID_OTHER_THAN_AUTH_ID: true,
    UPDATE_ID_MISSING_INFORMATION: true,
  }),
]);

export type CheckPrimaryIdEnumsCF = t.TypeOf<typeof CheckPrimaryIdEnumsCF>;

export const existingClientPrimaryIdCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "client", "checkExpiredOrStolen"],
  inputCodec: t.void,
  outputCodec: CheckPrimaryIdEnumsCF,
  errorCodec: GenericError,
});

const ExistingClientCheckKycCF = t.type({
  kycNeeded: t.boolean,
  highRisk: t.boolean,
  foreign: t.boolean,
});
export type ExistingClientCheckKycCF = t.TypeOf<
  typeof ExistingClientCheckKycCF
>;

export const existingClientKYCCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "client", "checkKyc"],
  inputCodec: t.void,
  outputCodec: ExistingClientCheckKycCF,
  errorCodec: GenericError,
});

const ExistingClientCheckPhoneAndEmail = t.type({
  phoneNumberNeeded: t.boolean,
  emailAddressNeeded: t.boolean,
});
export type ExistingClientCheckPhoneAndEmail = t.TypeOf<
  typeof ExistingClientCheckPhoneAndEmail
>;

export const existingClientPhoneAndEmailCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "client", "checkPhoneAndEmail"],
  inputCodec: t.void,
  outputCodec: ExistingClientCheckPhoneAndEmail,
  errorCodec: GenericError,
});

export const RestoreErrorKeys = t.keyof(
  {
    TOO_EARLY: true,
  },
  "RestoreErrorKeys"
);
export type RestoreErrorKeys = t.TypeOf<typeof RestoreErrorKeys>;

export const RestoreError = t.type(
  {
    status: RestoreErrorKeys,
  },
  "RestoreError"
);
export type RestoreError = t.TypeOf<typeof RestoreError>;

export const newestApplicationInProgress = apiCall({
  path: [
    "packages",
    "loans",
    "genericloans",
    "restore",
    "newestApplicationInProgress",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: NewestApplicationInProgressOutput,
  errorCodec: RestoreError,
});

export const DeleteSavedApplicationRequest = t.type({
  processId: t.string,
});
export type DeleteSavedApplicationRequest = t.TypeOf<
  typeof DeleteSavedApplicationRequest
>;

export const deleteExistingApplication = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "genericloans",
    "restore",
    "deleteExistingCAApplication",
  ],
  inputCodec: DeleteSavedApplicationRequest,
  outputCodec: t.unknown,
});

export const RestoreNewestApplicationInput = t.type({
  restoreWithDialog: t.boolean,
});
export type RestoreNewestApplicationInput = t.TypeOf<
  typeof RestoreNewestApplicationInput
>;

export const restoreNewestApplication = apiCall({
  inputCodec: RestoreNewestApplicationInput,
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "genericloans",
    "restore",
    "restoreNewestApplication",
  ],
  errorCodec: RestoreError,
});

const SectionType = t.keyof({
  MainIncome: true,
  AdditionalIncome: true,
  Refinancing: true,
  OptionalDocuments: true,
  NewRequiredDocuments: true,
});

export type SectionType = t.TypeOf<typeof SectionType>;

const DocumentMetadata = t.type(
  {
    applicationElementID: NonEmptyString,
    clientID: optionFromNullable(NonEmptyString),
    documentTypeID: NonEmptyString,
    documentTypeName: NonEmptyString,
    section: NonEmptyString,
    translationCode: RuntimeLocaleKey,
  },
  "DocumentMetadata"
);

export type DocumentMetadata = t.TypeOf<typeof DocumentMetadata>;

export const UploadedDocumentData = t.type(
  {
    section: SectionType,
    subSection: LocalizedString,
    metadata: DocumentMetadata,
    docId: optionFromUndefined(NonEmptyString),
    mimeType: optionFromNullable(DocumentMime),
    documentName: LocalizedString,
    requiredDocumentStatus: t.keyof({
      NEW: true,
      UPLOADED: true,
      UPLOADED_BY_CLIENT: true,
      UPLOADING: true,
      UPLOADING_BY_CLIENT: true,
      SEALING: true,
    }),
    uploadedTimestamp: optionFromUndefined(t.number),
    uploadNote: optionFromUndefined(t.string),
    oldDocId: optionFromUndefined(t.string),
    uniqueLfId: optionFromUndefined(t.string),
  },
  "UploadedDocumentData"
);

export type UploadedDocumentData = t.TypeOf<typeof UploadedDocumentData>;

export const appStateRequiredDocuments = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "appStateRequiredDocuments",
  ],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const appStateRequiredDocumentsReviewOffer = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "appStateRequiredDocumentsReviewOffer",
  ],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const requiredDocuments = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "requiredDocuments"],
  inputCodec: t.void,
  outputCodec: t.array(UploadedDocumentData),
});

export const previewRequiredDocuments = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "previewRequiredDocuments",
  ],
  inputCodec: t.void,
  outputCodec: t.array(UploadedDocumentData),
});

export const CheckUploadChangesOutput = t.type({
  uploadChanges: t.boolean,
  uploadConfirmed: t.boolean,
});
export type CheckUploadChangesOutput = t.TypeOf<
  typeof CheckUploadChangesOutput
>;

export const checkUploadChanges = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "checkUploadChanges",
  ],
  inputCodec: t.void,
  outputCodec: CheckUploadChangesOutput,
});

export const RequiredDocumentMetadata = t.type({
  applicationElementID: NonEmptyString,
  clientID: optionFromNullable(NonEmptyString),
  documentTypeID: NonEmptyString,
  documentTypeName: NonEmptyString,
  section: NonEmptyString,
  translationCode: RuntimeLocaleKey,
});

export type RequiredDocumentMetadata = t.TypeOf<
  typeof RequiredDocumentMetadata
>;

export const UploadDocumentInput = t.type({
  documentContent: CompressedFileContent,
  filename: t.string,
  mimeType: t.string,
  requiredDocumentMetadata: RequiredDocumentMetadata,
});

export type UploadDocumentInput = t.TypeOf<typeof UploadDocumentInput>;

export const CheckOptionalDocumentInput = t.type({
  documentTypeId: t.string,
  oldDocId: t.string,
  documentContent: CompressedFileContent,
  filename: t.string,
  mimeType: t.string,
});
export type CheckOptionalDocumentInput = t.TypeOf<
  typeof CheckOptionalDocumentInput
>;

export const CheckNewOptionalDocumentInput = t.type({
  documentContent: CompressedFileContent,
  filename: t.string,
  mimeType: t.string,
});
export type CheckNewOptionalDocumentInput = t.TypeOf<
  typeof CheckOptionalDocumentInput
>;

export const ConfirmDocumentUploadInput = t.type({
  uploadNote: t.string,
});
export type ConfirmDocumentUploadInput = t.TypeOf<
  typeof ConfirmDocumentUploadInput
>;

export const CheckNewRequiredDocumentInput = t.type({
  documentTypeId: t.string,
  translationCode: t.string,
  documentContent: CompressedFileContent,
  filename: t.string,
  mimeType: t.string,
  uniqueLfId: t.string,
});

export type CheckNewRequiredDocumentInput = t.TypeOf<
  typeof CheckNewRequiredDocumentInput
>;

export const UploadLinkDataPollingStatus = t.keyof({
  BANKER_INFO_UNCHANGED: true,
  BANKER_INFO_NOT_FOUND: true,
  BANKER_INFO_CHANGED: true,
  LINK_EXPIRED: true,
  LINK_DATA_NOT_FOUND: true,
  OK: true,
  UPLOAD_CONFIRMED: true,
});
export type UploadLinkDataPollingStatus = t.TypeOf<
  typeof UploadLinkDataPollingStatus
>;

export const GetLinkDataOutput = t.type({
  emailUploadLinkData: optionFromNullable(EmailUploadLinkData),
  status: UploadLinkDataStatus,
});

export const GetLinkDataPollingOutput = t.type({
  emailUploadLinkData: optionFromNullable(EmailUploadLinkData),
  status: UploadLinkDataPollingStatus,
});

export type EmailUploadLinkData = t.TypeOf<typeof EmailUploadLinkData>;

export const uploadDocument = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "upload"],
  inputCodec: UploadDocumentInput,
  outputCodec: UploadDocumentOutput,
});

export const uploadDocumentByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "uploadByClient"],
  inputCodec: UploadDocumentInput,
  outputCodec: UploadDocumentOutput,
});

export const confirmDocument = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "confirm"],
  inputCodec: ConfirmDocumentUploadInput,
  outputCodec: ConfirmDocumentUploadOutput,
});

export const confirmDocumentByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "confirmByClient"],
  inputCodec: ConfirmDocumentUploadInput,
  outputCodec: ConfirmDocumentUploadOutput,
});

export const confirmPOIByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "confirmPOIByClient",
  ],
  inputCodec: ConfirmDocumentUploadInput,
  outputCodec: ConfirmDocumentUploadOutput,
});

export const checkOptionalDocument = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "checkOptionalDoc"],
  inputCodec: CheckOptionalDocumentInput,
  outputCodec: UploadDocumentOutput,
});

export const checkOptionalDocumentByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "checkOptionalDocByClient",
  ],
  inputCodec: CheckOptionalDocumentInput,
  outputCodec: UploadDocumentOutput,
});

export const checkNewOptionalDocument = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "checkOptionalDoc"],
  inputCodec: CheckNewOptionalDocumentInput,
  outputCodec: UploadDocumentOutput,
});

export const checkNewOptionalDocumentByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "checkOptionalDocByClient",
  ],
  inputCodec: CheckNewOptionalDocumentInput,
  outputCodec: UploadDocumentOutput,
});

export const confirmOptionalDocumentUpload = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "confirmOptionalDocUpload",
  ],
  inputCodec: ConfirmDocumentUploadInput,
  outputCodec: ConfirmDocumentUploadOutput,
});

export const confirmOptionalDocumentUploadByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "confirmOptionalDocUploadByClient",
  ],
  inputCodec: ConfirmDocumentUploadInput,
  outputCodec: ConfirmDocumentUploadOutput,
});

export const cancelOptionalDocUpload = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "cancelOptionalDocUpload",
  ],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const cancelOptionalDocUploadByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "cancelOptionalDocUploadByClient",
  ],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const removeOptionalDoc = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "removeOptionalDoc"],
  inputCodec: t.type({
    docId: NonEmptyString,
  }),
  outputCodec: t.unknown,
});

export const removeOptionalDocByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "removeOptionalDocByClient",
  ],
  inputCodec: t.type({
    docId: NonEmptyString,
  }),
  outputCodec: t.unknown,
});

export const checkNewRequiredDocument = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "checkNewRequiredDoc",
  ],
  inputCodec: CheckNewRequiredDocumentInput,
  outputCodec: UploadDocumentOutput,
});

export const checkNewRequiredDocumentByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "checkNewRequiredDocByClient",
  ],
  inputCodec: CheckNewRequiredDocumentInput,
  outputCodec: UploadDocumentOutput,
});

export const confirmNewRequiredDocumentUpload = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "confirmNewRequiredDocUpload",
  ],
  inputCodec: ConfirmDocumentUploadInput,
  outputCodec: ConfirmDocumentUploadOutput,
});

export const confirmNewRequiredDocumentUploadByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "confirmNewRequiredDocUploadByClient",
  ],
  inputCodec: ConfirmDocumentUploadInput,
  outputCodec: ConfirmDocumentUploadOutput,
});

export const cancelNewRequiredDocUpload = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "cancelNewRequiredDocUpload",
  ],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const cancelNewRequiredDocUploadByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "cancelNewRequiredDocUploadByClient",
  ],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const removeNewRequiredDoc = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "removeNewRequiredDoc",
  ],
  inputCodec: t.type({
    docId: NonEmptyString,
  }),
  outputCodec: t.unknown,
});

export const removeNewRequiredDocByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "removeNewRequiredDocByClient",
  ],
  inputCodec: t.type({
    docId: NonEmptyString,
  }),
  outputCodec: t.unknown,
});

export const saveEmailUploadLinkData = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "upload",
    "saveEmailUploadLinkData",
  ],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const getLinkData = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "upload",
    "noauth",
    "getLinkData",
  ],
  inputCodec: t.type({
    linkId: NonEmptyString,
  }),
  outputCodec: GetLinkDataOutput,
});

export const getLinkDataPolling = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "upload",
    "getLinkDataPolling",
  ],
  inputCodec: t.type({
    linkId: NonEmptyString,
    bankerFlowId: UUID,
  }),
  outputCodec: GetLinkDataPollingOutput,
});

export const reassignEmailFlow = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["clients", "identification", "emailUpload", "reassign"],
  inputCodec: t.type({
    bankerFlowId: UUID,
  }),
  outputCodec: t.type({
    bankerFlowId: UUID,
  }),
});

export const cancelUpload = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "upload", "cancel"],
  inputCodec: RequiredDocumentMetadata,
  outputCodec: t.unknown,
});

export const cancelUploadByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "upload",
    "cancelByClient",
  ],
  inputCodec: RequiredDocumentMetadata,
  outputCodec: t.unknown,
});

export const sendLinkToClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "upload",
    "sendLinkToClient",
  ],
  inputCodec: t.unknown,
  outputCodec: t.unknown,
});

export const removeDocumentByClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "removeByClient"],
  inputCodec: t.type({
    docId: NonEmptyString,
  }),
  outputCodec: t.unknown,
});

export const removeDocument = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "document", "remove"],
  inputCodec: t.type({
    docId: NonEmptyString,
  }),
  outputCodec: t.unknown,
});

export const CheckRequiredDocumentsStatus = t.type({
  requiredDocumentsReceived: t.boolean,
});

export const checkRequiredDocuments = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "document",
    "checkRequiredDocuments",
  ],
  inputCodec: t.void,
  outputCodec: CheckRequiredDocumentsStatus,
});

const CreditorData = t.type(
  {
    code: NonEmptyString,
    name: NonEmptyString,
  },
  "CreditorData"
);

export type CreditorData = t.TypeOf<typeof CreditorData>;

const LoanProviderOutput = t.type(
  {
    loanProviderList: nonEmptyArray(CreditorData),
  },
  "LoanProviderOutput"
);

export type LoanProviderOutput = t.TypeOf<typeof LoanProviderOutput>;

export const loanProviders = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "creditBureau", "loanProviders"],
  inputCodec: t.void,
  outputCodec: LoanProviderOutput,
});

const SelectLoanForRefinancingInput = t.type(
  {
    recordId: NonEmptyString,
    selectedForRefinance: t.boolean,
    type: LoanType,
  },
  "SelectLoanForRefinancingInput"
);

export type SelectLoanForRefinancingInput = t.TypeOf<
  typeof SelectLoanForRefinancingInput
>;

const SelectLoanForRefinancingOutput = t.type(
  {
    maxLoanAmount: NonNegative,
  },
  "SelectLoanForRefinancingOutput"
);

export type SelectLoanForRefinancingOutput = t.TypeOf<
  typeof SelectLoanForRefinancingOutput
>;

export const selectLoanForRefinancing = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "creditBureau",
    "selectLoanForRefinancing",
  ],
  inputCodec: SelectLoanForRefinancingInput,
  outputCodec: SelectLoanForRefinancingOutput,
});

const LoanProviderSelectedInput = t.type(
  {
    creditor: NonEmptyString,
    type: LoanType,
  },
  "LoanProviderSelectedInput"
);

export type LoanProviderSelectedInput = t.TypeOf<
  typeof LoanProviderSelectedInput
>;

const LoanProviderSelectedOutput = optionFromUndefined(
  t.type({
    accountForDisbursmentHint: RuntimeLocaleKey,
    variableSymbolHint: optionFromUndefined(RuntimeLocaleKey),
    accountForDisbursment: optionFromUndefined(NonEmptyString),
    accountReadonly: t.boolean,
  }),
  "LoanProviderSelectedOutput"
);

export type LoanProviderSelectedOutput = t.TypeOf<
  typeof LoanProviderSelectedOutput
>;

export const eqLoanProviderSelectedInput = eq.getStructEq({
  creditor: eq.eqString,
  type: eq.eqString,
});

export const loanProviderSelected = apiCall({
  inputEq: eqLoanProviderSelectedInput,
  path: [
    "packages",
    "loans",
    "standard-loan",
    "creditBureau",
    "loanProviderSelected",
  ],
  inputCodec: LoanProviderSelectedInput,
  outputCodec: LoanProviderSelectedOutput,
});

export const submitAccountsForRefinancing = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "creditBureau",
    "submitAccountsForRefinancing",
  ],
  inputCodec: AccountsForRefinancingOutput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const LTVWithinLimitsStatus = t.keyof(
  {
    PENDING: true,
    OK: true,
    NOK: true,
  },
  "LTVWithinLimitsStatus"
);

const IncomeAndPersonalDataOutput = t.type(
  {
    needAdditionalIncomeStep: t.boolean,
    isReworkDoPolling: t.boolean,
    ltvWithinLimitsStatus: optionFromUndefined(LTVWithinLimitsStatus),
  },
  "IncomeAndPersonalDataOutput"
);

export type IncomeAndPersonalDataOutput = t.TypeOf<
  typeof IncomeAndPersonalDataOutput
>;

export const confirmIncomeAndPersonalData = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "income", "confirm"],
  inputCodec: IncomeAndPersonalDataDTO,
  outputCodec: IncomeAndPersonalDataOutput,
});

export const resetCredentialsProcess = apiCall({
  path: [
    "clients",
    "identification",
    "mobileCreateProfile",
    "credentials",
    "resetCredentialsProcess",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const SendPushOutput = t.intersection(
  [
    smartKeyApi.SendPushOutput,
    t.type(
      {
        applicationRejected: t.boolean,
      },
      "LoansAuthorizationSendPushOutput"
    ),
  ],
  "SendPushOutput"
);
export type SendPushOutput = t.TypeOf<typeof SendPushOutput>;

export const authorizeWithPush = apiCall({
  path: ["packages", "loans", "standard-loan", "authorizeWithPush"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: SendPushOutput,
});

export const GenerateQRCodeError = t.type(
  {
    error: t.keyof(
      {
        UserBlocked: true,
        ApplicationRejected: true,
      },
      "LoansAuthorizationGenerateQRCodeError"
    ),
  },
  "LoansAuthorizationGenerateQRCodeOutput"
);

const GenerateQRCodeOutput = t.union(
  [smartKeyApi.GenerateQRCodeSuccess, GenerateQRCodeError],
  "GenerateQRCodeOutput"
);
export type GenerateQRCodeOutput = t.TypeOf<typeof GenerateQRCodeOutput>;

export const generateAuthorizationQR = apiCall({
  path: ["packages", "loans", "standard-loan", "generateAuthorizationQR"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: GenerateQRCodeOutput,
});

const PersonalDataOptionsNotReadyResult = t.type(
  {
    status: t.literal("RETRY"),
  },
  "PersonalDataOptionsNotReadyResult"
);

const PersonalDataAttemptsExceededOutput = t.type(
  {
    status: t.literal("ATTEMPTS_EXCEEDED"),
  },
  "PersonalDataAttemptsExceededOutput"
);

const PersonalDataOptionsApprovedOutput = t.type(
  {
    status: t.literal("OK"),
    incomeOptions: IncomeOptionsOutput,
    personalDataOptions: PersonalDataFromOutput,
    result: CreditChecksApproved,
  },
  "PersonalDataOptionsApprovedOutput"
);

export type PersonalDataOptionsApprovedOutput = t.TypeOf<
  typeof PersonalDataOptionsApprovedOutput
>;

const PersonalDataOptionsRejectedOutput = t.type(
  {
    status: t.literal("OK"),
    result: CreditChecksRejected,
  },
  "PersonalDataOptionsRejectedOutput"
);

export const PersonalDataOptionsOutput = t.union(
  [
    PersonalDataOptionsNotReadyResult,
    PersonalDataOptionsApprovedOutput,
    PersonalDataOptionsRejectedOutput,
    PersonalDataAttemptsExceededOutput,
  ],
  "PersonalDataOptionsOutput"
);

export type PersonalDataOptionsOutput = t.TypeOf<
  typeof PersonalDataOptionsOutput
>;

export const getPersonalDataOptions = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: PersonalDataOptionsOutput,
  path: [
    "packages",
    "loans",
    "standard-loan",
    "application",
    "getPersonalDataOptions",
  ],
});

export const getIncome = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["packages", "loans", "standard-loan", "income", "get"],
  outputCodec: t.type({ income: IncomeOutput }),
});

export const AccCollectionErrorCode = t.type(
  {
    id: t.string,
    code: t.string,
  },
  "AccCollectionErrorCode"
);

export const DisbursementAccountsError = t.union([
  AccCollectionErrorCode,
  GenericError,
]);

export const getDisbursementAccounts = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "offer",
    "getDisbursementAccounts",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: t.array(LocalizedString),
  errorCodec: DisbursementAccountsError,
});

const KycNeeded = t.type(
  {
    isKycNeeded: t.boolean,
  },
  "KycNeeded"
);

export type KycNeeded = t.TypeOf<typeof KycNeeded>;

export const isKycNeeded = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "knowYourCustomer",
    "isKycNeeded",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: KycNeeded,
});

const ExternalLiabilitiesSelected = t.type(
  {
    externalLiabilities: t.boolean,
  },
  "ExternalLiabilitiesSelected"
);

export type ExternalLiabilitiesSelected = t.TypeOf<
  typeof ExternalLiabilitiesSelected
>;

export const areExternalLiabilitiesSelected = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "customerOffer",
    "areExternalLiabilitiesSelected",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ExternalLiabilitiesSelected,
});

export const CBRejectedOutput = t.type(
  {
    cbRejected: t.boolean,
  },
  "CBRejectedOutput"
);
export type CBRejectedOutput = t.TypeOf<typeof CBRejectedOutput>;

export const getIsCBRejected = apiCall({
  path: ["packages", "loans", "standard-loan", "application", "isCBRejected"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CBRejectedOutput,
});

export const LTVWithinLimitsOutput = t.type(
  {
    status: LTVWithinLimitsStatus,
  },
  "LTVWithinLimitsOutput"
);
export type LTVWithinLimitsOutput = t.TypeOf<typeof LTVWithinLimitsOutput>;

export const checkLtvWithinLimits = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "application",
    "isLtvWithinLimits",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: LTVWithinLimitsOutput,
});

export const setDisbursementAccount = apiCall({
  path: ["packages", "loans", "standard-loan", "offer", "disbursementAccount"],
  inputCodec: LocalizedString,
  inputEq: eq.eqString,
  outputCodec: t.unknown,
});

const GetApplicationOutput = t.type({
  loanType: t.literal("SL"),
  restoreData: RestoreNewestLoanApplicationOutput,
});

export type GetApplicationOutput = t.TypeOf<typeof GetApplicationOutput>;

export const getApplicationWithRestoreDataAndRestore = apiCall({
  path: [
    "packages",
    "loans",
    "client",
    "getApplicationWithRestoreDataAndRestore",
  ],
  inputCodec: t.type({
    applicationId: t.string,
  }),
  inputEq: eq.getStructEq({
    applicationId: eq.eqString,
  }),
  outputCodec: GetApplicationOutput,
  errorCodec: RestoreError,
});

export const getLastSavedLoan = apiCall({
  path: ["packages", "loans", "client", "getLastSavedLoan"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: GetApplicationOutput,
});

const CheckApplicationStatusOutput = t.type({
  lfStatus: LFStatus,
  rejected: t.boolean,
});

export type CheckApplicationStatus = t.TypeOf<
  typeof CheckApplicationStatusOutput
>;

export const checkApplicationStatus = apiCall({
  path: ["packages", "loans", "standard-loan", "checkApplicationStatus"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CheckApplicationStatusOutput,
});

const SLResultsMicropayment = t.type({ status: t.literal("MICROPAYMENT") });
type SLResultsMicropayment = t.TypeOf<typeof SLResultsMicropayment>;

const SLResultsPending = t.type({ status: t.literal("PENDING") });
type SLResultsPending = t.TypeOf<typeof SLResultsPending>;

const SLResultsFailure = t.type({ status: t.literal("ERROR") });
const SLResultsRejected = t.type({ status: t.literal("REJECTED") });
const SLResultsRedirectToTheBank = t.type({
  status: t.literal("REDIRECT_TO_THE_BANK"),
});
const SLResultsContinue = t.type({ status: t.literal("CONTINUE") });
type SLResultsContinue = t.TypeOf<typeof SLResultsContinue>;

const SLResultsNotifyClientOfDelay = t.type({
  status: t.literal("NOTIFY_CLIENT_OF_DELAY"),
});
const SLResultsNotifyClientOfDelayWithErrors = t.type({
  status: t.literal("NOTIFY_CLIENT_OF_DELAY_ERRORS"),
});

const SLResultsSuccess = t.type({
  status: t.literal("DONE"),
  stepsResults: nonEmptyArray(
    t.type({
      type: t.keyof({ SUCCESS: true, ERROR: true }),
      message: LocalizedString,
    })
  ),
});

export type SLResultsSuccess = t.TypeOf<typeof SLResultsSuccess>;

const SLResultsOutput = t.union([
  SLResultsPending,
  SLResultsFailure,
  SLResultsRejected,
  SLResultsRedirectToTheBank,
  SLResultsNotifyClientOfDelay,
  SLResultsNotifyClientOfDelayWithErrors,
  SLResultsSuccess,
  SLResultsContinue,
  SLResultsMicropayment,
]);

export type SLResultsOutput = t.TypeOf<typeof SLResultsOutput>;

export const slResults = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "results"],
  inputCodec: t.void,
  outputCodec: SLResultsOutput,
});

const SignatureType = t.keyof({
  OtpSms: true,
  Click2Sign: true,
});

export type SignatureType = t.TypeOf<typeof SignatureType>;

export const SignatureTypeOutput = t.type(
  {
    signatureType: optionFromNullable(SignatureType),
  },
  "SignatureTypeOutput"
);

export type SignatureTypeOutput = t.TypeOf<typeof SignatureTypeOutput>;

export const getSignatureType = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "getSignatureType"],
  outputCodec: SignatureTypeOutput,
});

export const GetBrokersInput = t.type(
  {
    partialEmail: t.string,
  },
  "GetBrokersInput"
);

export interface GetBrokersInput extends t.TypeOf<typeof GetBrokersInput> {}

export const eqGetBrokersInput: Eq<GetBrokersInput> = eq.getStructEq({
  partialEmail: eq.eqString,
});

export const GetBrokersOutput = t.array(t.string, "GetBrokersOutput");

export interface GetBrokersOutput extends t.TypeOf<typeof GetBrokersOutput> {}

export const getBrokers = apiCall({
  path: ["packages", "loans", "standard-loan", "assigned-broker", "brokers"],
  inputCodec: GetBrokersInput,
  inputEq: eqGetBrokersInput,
  outputCodec: GetBrokersOutput,
});

export const getAssignedBrokerForBanker = apiCall({
  path: ["packages", "loans", "standard-loan", "assigned-broker", "get"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: AssignedBrokerForBanker,
});

const saveAssignedBrokerForBankerInput = t.type({
  email: t.string,
});

const eqSaveAssignedBrokerForBankerInput = eq.getStructEq({
  email: eq.eqString,
});

const saveAssignedBrokerForBankerOutput = t.union([
  t.type({
    status: t.literal("VALID"),
  }),
  t.type({
    status: t.literal("INVALID"),
  }),
]);

export const saveAssignedBrokerForBanker = apiCall({
  path: ["packages", "loans", "standard-loan", "assigned-broker", "save"],
  inputCodec: saveAssignedBrokerForBankerInput,
  inputEq: eqSaveAssignedBrokerForBankerInput,
  outputCodec: saveAssignedBrokerForBankerOutput,
});

export const removeAssignedBrokerForBanker = apiCall({
  path: ["packages", "loans", "standard-loan", "assigned-broker", "remove"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const ApplicationLockedInput = t.type(
  {
    applicationId: t.string,
  },
  "ApplicationLockedInput"
);

export const ApplicationLockedOutput = t.type({
  locked: t.boolean,
});
export type ApplicationLockedOutput = t.TypeOf<typeof ApplicationLockedOutput>;

export const isApplicationLocked = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "applicationState",
    "isApplicationLocked",
  ],
  inputCodec: ApplicationLockedInput,
  inputEq: eq.getStructEq({ applicationId: eq.eqString }),
  outputCodec: ApplicationLockedOutput,
});

export const linkGenerationNow = apiCall({
  inputEq: eqLinkGenerationInput,
  path: [
    "packages",
    "loans",
    "standard-loan",
    "email",
    "activationLink",
    "now",
  ],
  inputCodec: LinkGenerationInput,
  outputCodec: LinkGenerationOutput,
  errorCodec: LinkGenerationError,
});

export const packagesList = apiCall({
  path: ["packages", "loans", "standard-loan", "packages", "list"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: PackageListResponse,
  errorCodec: GetListError,
});

export const choosePackageType = apiCall({
  path: ["packages", "loans", "standard-loan", "packages", "type"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: ChoosePackageTypeInput,
  outputCodec: t.unknown,
});

export const transitionToMasterCard = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "packages",
    "transitionToMasterCard",
  ],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const initVirtualCard = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "virtualCard",
    "initVirtualCard",
  ],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: InitVirtualCardResponse,
});

export const savePhysicalCardValue = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "virtualCard",
    "savePhysicalCardValue",
  ],
  inputCodec: PhysicalCardInput,
  outputCodec: t.unknown,
});

export const virtualCardNext = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "virtualCard", "next"],
  inputCodec: t.void,
  outputCodec: t.unknown,
});
