import { LocalizedString, NonNegativeInteger } from "design-system";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { Reader } from "fp-ts/Reader";
import { Option } from "fp-ts/Option";
import { MoneyAmount, optionFromUndefined } from "../globalDomain";
import { LocaleKey } from "../intl";
import { LoanItem, LoanType, UploadedDocumentData } from "./api";
import * as t from "io-ts";
import { IncomeOutput } from "./IncomeForm/domain";
import { LiabilitiesVariant } from "../MortgageDashboard/Registers/CBResults/Liabilities";

export type AdditionalIncomeInfo = {
  hasAdditionalIncome: Option<boolean>;
  additionaIncomes: Option<IncomeOutput[]>;
};

export type SelectedCompany = {
  companyName: string;
  companyIco: string;
};

export const StandardLoanFlowType = t.keyof(
  {
    InPerson: true,
    "3P": true,
    TLSAgent: true,
    HomeBanking: true,
    SmartBanking: true,
    PWSRemote: true,
  },
  "StandardLoanFlowType"
);
export type StandardLoanFlowType = t.TypeOf<typeof StandardLoanFlowType>;

export function foldFlowType<R>(matches: {
  whenHomeBanking: IO<R>;
  whenSmartBanking: IO<R>;
  when3P: IO<R>;
  whenInPerson: IO<R>;
  whenTLSAgent: IO<R>;
  whenPWSRemote: IO<R>;
}): Reader<StandardLoanFlowType, R> {
  return flowType => {
    switch (flowType) {
      case "HomeBanking":
        return matches.whenHomeBanking();
      case "SmartBanking":
        return matches.whenSmartBanking();
      case "3P":
        return matches.when3P();
      case "InPerson":
        return matches.whenInPerson();
      case "TLSAgent":
        return matches.whenTLSAgent();
      case "PWSRemote":
        return matches.whenPWSRemote();
    }
  };
}

export function shouldAskCompany(sourceOfIncome: Option<string>): boolean {
  return pipe(
    sourceOfIncome,
    option.exists(item => item === "Employed" || item === "SelfEmployed")
  );
}

export function getTypeOfProductLabel(product: LoanType): LocaleKey {
  switch (product) {
    case "CREDIT_CARD":
      return "StandardLoan.AccountsForRefinancing.Products.creditCardLabel";
    case "LOAN":
      return "StandardLoan.AccountsForRefinancing.Products.loanLabel";
    case "OVERDRAFT":
      return "StandardLoan.AccountsForRefinancing.Products.overdraftLabel";
  }
}

export type ExternalLiabilityFormModel = LoanItem & {
  creditorName: string;
  accountForDisbursment: string;
  variableSymbol: string;
  creditCardNumber: string;
};

export type LiabilityFormModel = LoanItem & {
  selected: boolean;
  startDate: Option<Date>;
};

export type RestoredRecapData = {
  loanAmount: MoneyAmount;
  instalment: MoneyAmount;
  tenor: NonNegativeInteger;
  interestRate: number;
  feStep: LocalizedString;
};

export type RefuseExistingClientReason =
  | "HasUserId"
  | "IsMultipleUser"
  | "IsExisting"
  | "IsPersonalNumberMatch";

export function foldLiabilitiesVariant<T>(
  variant: LiabilitiesVariant,
  match: {
    onMtg: () => T;
    onCf: () => T;
  }
): T {
  switch (variant) {
    case "MTG":
      return match.onMtg();
    case "CF":
      return match.onCf();
  }
}

export const UnderAgeError = t.type({
  id: t.literal("UnderAgeError"),
});
export type UnderAgeError = t.TypeOf<typeof UnderAgeError>;

export function foldRequiredDocumentStatus<R>(matches: {
  whenNew: IO<R>;
  whenUploaded: IO<R>;
  whenUploadedByClient: IO<R>;
  whenUploading: IO<R>;
  whenUploadingByClient: IO<R>;
  whenSealing: IO<R>;
}): Reader<UploadedDocumentData["requiredDocumentStatus"], R> {
  return flowType => {
    switch (flowType) {
      case "NEW":
        return matches.whenNew();
      case "UPLOADED":
        return matches.whenUploaded();
      case "UPLOADED_BY_CLIENT":
        return matches.whenUploadedByClient();
      case "UPLOADING":
        return matches.whenUploading();
      case "UPLOADING_BY_CLIENT":
        return matches.whenUploadingByClient();
      case "SEALING":
        return matches.whenSealing();
    }
  };
}

export const AssignedBrokerForBankerInfo = t.type({
  firstName: optionFromUndefined(t.string),
  lastName: optionFromUndefined(t.string),
  phoneNumber: optionFromUndefined(t.string),
  email: optionFromUndefined(t.string),
  brokerageCompany: optionFromUndefined(t.string),
});

export type AssignedBrokerForBankerInfo = t.TypeOf<
  typeof AssignedBrokerForBankerInfo
>;

export const AssignedBrokerForBanker = t.type({
  brokerInfo: optionFromUndefined(AssignedBrokerForBankerInfo),
});

export type AssignedBrokerForBanker = t.TypeOf<typeof AssignedBrokerForBanker>;
